import axios from "axios";

export default {
    state: {
        locations: [],
        location: {}
    },

    actions: {
        async fetchLocations (context, filter) {
            try {
                const url = `/${process.env.VUE_APP_API_PATH}/locations-get-all`;

                const response = await axios.get(url, {
                    headers: {
                        "Content-Type": 'application/json'
                    },
                    params: filter
                })

                const locations = response.data;
                context.commit('setLocations', locations);
            }
            catch (error) {
                console.log(error);
            }
        },

        async fetchOneLocation (context, name) {
            try {
                const url = `/${process.env.VUE_APP_API_PATH}/locations-get-one`;

                const response = await axios.get(url, {
                    headers: {
                        "Content-Type": 'application/json'
                    },
                    params: name
                })

                const location = response.data;
                context.commit('setOneLocation',location);
            }
            catch (error) {
                console.log(error);
            }
        }
    },

    mutations: {
        setLocations (state, locations) {
            state.locations = locations;
        },

        setOneLocation (state, location) {
            state.location = location;
        }
    },

    getters: {
        getLocations (state) {
            return state.locations;
        },

        getOneLocation (state) {
            return state.location;
        }
    }
}