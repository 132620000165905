<template>
    <div class="preloader">
        <div class="page__preloader">
            <div class="page__preloader-content">
                <div class="spinner-border page__preloader-spiner text-light" role="status">
                    <span class="visually-hidden">Loading...</span>
                </div>
                <h2>Loading...</h2>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    @import '@/assets/styles/preloaders/page-preloader.scss';
</style>

<script>
</script>