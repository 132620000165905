<template>
    <div class="skeleton__apartment">
        <div class="skeleton__apartment-img"></div>
        <div class="skeleton__apartment-content">
            <div class="skeleton__apartment-name"></div>
            <div class="skeleton__apartment-info">
                <div class="skeleton__apartment-beds"></div>
                <div class="skeleton__apartment-bads"></div>
                <div class="skeleton__apartment-square"></div>
            </div>
            <div class="skeleton__apartment-location"></div>
            <div class="skeleton__apartment-price">
                <div class="skeleton__apartment-number"></div>
                <div class="skeleton__apartment-for"></div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
    .skeleton {
        &__apartment {
            margin-bottom: 10px;
            &-img {
                width: 290px;
                height: 310px;
                background: linear-gradient(
                100deg,
                rgba(255, 255, 255, 0) 40%,
                rgba(255, 255, 255, .5) 50%,
                rgba(255, 255, 255, 0) 60%
                ) #b0b0b0;
                border-radius: 8px;
                background-size: 200% 100%;
                background-position-x: 180%;
                animation: loading 0.5s ease-in-out infinite;
            }

            &-content {
                width: 290px;
            }

            &-name {
                width: 100%;
                background: linear-gradient(
                100deg,
                rgba(255, 255, 255, 0) 40%,
                rgba(255, 255, 255, .5) 50%,
                rgba(255, 255, 255, 0) 60%
                ) #b0b0b0;
                height: 30px;
                margin: 15px 0;
                border-radius: 8px;
                background-size: 200% 100%;
                background-position-x: 180%;
                animation: loading 0.5s ease-in-out infinite;
            }

            &-info {
                display: flex;
                gap: 20px;
            }

            &-beds, &-bads {
                background: linear-gradient(
                100deg,
                rgba(255, 255, 255, 0) 40%,
                rgba(255, 255, 255, .5) 50%,
                rgba(255, 255, 255, 0) 60%
                ) #b0b0b0;
                width: 50px;
                height: 20px;
                border-radius: 8px;
                background-size: 200% 100%;
                background-position-x: 180%;
                animation: loading 0.5s ease-in-out infinite;
            }

            &-square {
                background-color: #b0b0b0;
                background: linear-gradient(
                100deg,
                rgba(255, 255, 255, 0) 40%,
                rgba(255, 255, 255, .5) 50%,
                rgba(255, 255, 255, 0) 60%
                ) #b0b0b0;
                width: 180px;
                height: 20px;
                border-radius: 8px;
                background-size: 200% 100%;
                background-position-x: 180%;
                animation: loading 0.5s ease-in-out infinite;
            }
        }
    }

    @keyframes loading {
        to {
            background-position-x: -20%;
        }
    }
</style>