import axios from "axios"

export default {
    state: {
        plan: [],
    },

    actions: {
        async fetchOnePlan(context, data) {
            try {
                const url = `/server-api/get-plan`;
                const response = await axios.get(url, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    params: data
                });
    
                const planData = response.data;
                context.commit('setOnePlan', planData);
            }
            catch (error) {
                console.log(error);
            }
        },
    },

    mutations: {
        setOnePlan(state, plan) {
            state.plan = plan;
        }
    },

    getters: {
        getOnePlan(state) {
            return state.plan;
        }
    }
}