<template>
    <div>
        <div class="error-text-container">
            <h2 class="error-title">At the moment you do not have enough rights. Obtain permissions and try again.</h2>
        </div>
        <offer/>
    </div>
</template>

<script>
import offer from '@/components/reused/offer.vue';

export default {
    components: {
        offer
    }
}
</script>